const Adapter = require('./adapter');

const CookieAdapter = require('./adapters/cookie');
const LocalAdapter = require('./adapters/local');
const SessionAdapter = require('./adapters/session');

/**
 * @typedef {import('./adapter')} Adapter
 */

/**
 * @template T
 * 
 * @typedef Storage.Attributes
 * @property {'cookie'|'local'|'session'} storage
 * @property {T} [default]
 */

const _name = Symbol('name'),
	_namespace = Symbol('namespace'),
	_attributes = Symbol('attributes'),
	_destroyed = Symbol('destroyed');

/**
 * @template T
 */
class Storage {
	/**
	 * @param {string} name
	 * @param {Storage.Attributes<T>} attributes
	 */
	constructor(name, attributes = {}) {
		attributes.byClient = attributes.byClient === undefined ? true : attributes.byClient;

		this.name = attributes.byClient && Ingtech.clientId ? `${name}/${Ingtech.clientId}` : name;
		this._attributes = attributes;

		this._storage = Adapter.create(attributes.storage || 'session');
		this._storage.init(this.name, attributes);

		this._data = {};
		this._defaultValues = attributes.default || {};

		setTimeout(() => {
			if (this.name != 'cookies-expirations') {
				Storage.StorageExpiration.set(this.name, attributes.expires ? attributes.expires : 'session');
			}
		})

		this.load();
	}

	/**
	 * @template {keyof T} K
	 * @param {K} prop
	 * @param {T[K]} value
	 * @param {boolean} autoApply
	 * @returns {this}
	 */
	set(prop, value, autoApply = true, defaultMode) {
		if (this[_destroyed]) throw new Error('You cannot use a destroyed storage.');
		if (prop == 'this') throw new Error('You cannot set "this".');

		if (value === null) return this.remove(prop, autoApply);

		this._data[prop] = convert(value);

		if (autoApply) {
			this.apply();
		}

		return this;
	}

	/**
	 * @template {keyof T} K
	 * @param {K} prop
	 * @param {boolean} autoApply
	 * @returns {T[K]}
	 */
	get(prop, autoLoad = true) {
		if (this[_destroyed]) throw new Error('You cannot use a destroyed storage');

		if (autoLoad) {
			this.load();
		}

		return restore(this._data[prop]) ?? this._defaultValues[prop];
	}

	/**
	 * 
	 * @param {keyof T} prop
	 * @param {boolean} autoApply
	 * @returns {this}
	 */
	remove(prop, autoApply = true) {
		if (this[_destroyed]) throw new Error('You cannot use a destroyed storage');

		delete this._data[prop];

		if (autoApply) {
			this.apply();
		}

		return this;
	}

	clear() {
		if (this[_destroyed]) throw new Error('You cannot use a destroyed storage');

		this._storage.destroy();
		this.load();

		return this;
	}

	destroy() {
		if (this[_destroyed]) throw new Error('You cannot use a destroyed storage');

		this._storage.destroy();
		this.load();

		this[_destroyed] = true;
	}

	apply() {
		if (this[_destroyed]) throw new Error('You cannot use a destroyed storage');

		this._storage.apply(this._data);

		return this;
	}

	save() {
		return this.apply();
	}

	load() {
		if (this[_destroyed]) throw new Error('You cannot use a destroyed storage');

		this._data = this._storage.load();

		return this;
	}

	isEmpty() {
		return Object.keys(this._data).length == 0;
	}

	/**
	 * @param {T} data
	 * @param {boolean} autoApply
	 * @returns {this}
	 */
	concat(data, autoApply = true) {
		if (this[_destroyed]) throw new Error('You cannot use a destroyed storage');

		this._data = Object.assign(this._data, data);

		if (autoApply) {
			this.apply();
		}

		return this;
	}

	/**
	 * @returns {T}
	 */
	data() {
		const data = {
			...this._defaultValues,
			...Object.entries(this._data).reduce((acc, [key, value]) => {
				acc[key] = restore(value);
				return acc;
			}, {})
		}

		return data;
	}

	/**
	 * 
	 * @returns {this & T}
	 */
	proxy() {
		this.load();

		return new Proxy(this._data, {
			set: (obj, prop, value) => {
				if (this[_destroyed]) throw new Error('You cannot use a destroyed storage');
				if (this[prop] instanceof Function) throw new TypeError(`Cannot set value on the protected key '${key}'`);

				this.set(prop, value);
				return true;
			},
			get: (obj, prop) => {
				if (this[_destroyed]) throw new Error('You cannot use a destroyed storage');
				if (this[prop] !== undefined) return this[prop];
				if (prop == 'this') return this;

				return this.get(prop);
			},
			ownKeys: (filter) => Object.keys(this.data()),
			has: (filter, key) => key in this.data(),
			getOwnPropertyDescriptor: (filter, key) => ({ configurable: true, enumerable: true, value: this.data(key) })
		});
	}

	// static clearSession() {
	// 	let expirations = Storage.StorageExpiration.proxy();

	// 	for (let [name, expires] of Object.entries(expirations)) {
	// 		if (expires == 'session') {
	// 			Cookies.remove(name);
	// 			cookies[name] = null;
	// 		}
	// 	}
	// }
}

function convert(value) {
	for (let customType of Storage.CustomType) {
		if (customType.validate(value)) {
			return {
				_isCustomType: true,
				type: customType.type,
				value: customType.set(value)
			};
		}
	}

	return value;
}

function restore(value) {
	if (value && typeof value == 'object' && value._isCustomType) {
		let customType = Storage.CustomType.find(ct => ct.type == value.type);

		return customType ? customType.get(value) : value;
	}

	return value;
}

setTimeout(() => {
	Storage.StorageExpiration = new Storage('cookies-expirations', { storage: 'local', byClient: false });
});


Storage.CustomType = [
	{
		type: 'moment',
		validate: value => value && typeof value == 'object' && value._isAMomentObject,
		get: value => moment(value.value),
		set: value => value.format()
	},
	{
		type: 'object',
		validate: value => value && typeof value == 'object' && !(value instanceof Array),
		get: value => value.value,
		set: value => Object.getOwnPropertyNames(value).reduce((obj, key) => (obj[key] = value[key], obj), {})
	}
];

Ingtech.Storage = Storage;
module.exports = Storage;