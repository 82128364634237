const Adapter = require('../adapter');

class CookieAdapter extends Adapter {
	init(name, attributes) {
		super.init(name, attributes);

		this.namespace = attributes.cookiesApi || Cookies;
	}

	load() {
		return JSON.parse(this.namespace.get(this.name) || '{}');
	}

	apply(data) {
    this.namespace.set(this.name, JSON.stringify(data), this.attributes);
	}

	destroy() {
		this.namespace.remove(this.name, this.attributes);
	}
}

Adapter.STORAGE.cookie = CookieAdapter;
module.exports = CookieAdapter;