const Adapter = require('../adapter');

class SessionAdapter extends Adapter {
	// init(name, attributes) {
	// 	super.init(name, attributes);
	// }

	load() {
		return JSON.parse(sessionStorage.getItem(this.name) || '{}');
	}

	apply(data) {
    sessionStorage.setItem(this.name, JSON.stringify(data));
	}

	destroy() {
		console.log('test 123123')
		sessionStorage.removeItem(this.name);
	}
}

Adapter.STORAGE.session = SessionAdapter;
module.exports = SessionAdapter;