class Adapter {
	static create(storageName) {
		let storage = new Adapter.STORAGE[storageName]();

		return storage;
	}

	init(name, attributes) {
		this.name = name;
		this.attributes = attributes;
	}

	/**
	 * Load data from storage
	 * 
	 * @return {Object}
	 */
	load() {
		throw new Error('You must implement the load method.');
	}
	
	/**
	 * Apply data to storage
	 * 
	 * @param {Object} data
	 * @return {void}
	 */
	apply(data) {
		throw new Error('You must implement the apply method.');
	}
	
	/**
	 * Destroy storage
	 * 
	 * @return {void}
	 */
	destroy() {
		throw new Error('You must implement the destroy method.');
	}
}

Adapter.STORAGE = {};

module.exports = Adapter;