const Adapter = require('../adapter');

class LocalAdapter extends Adapter {
	// init(name, attributes) {
	// 	super.init(name, attributes);
	// }

	load() {
		return JSON.parse(localStorage.getItem(this.name) || '{}');
	}

	apply(data) {
    localStorage.setItem(this.name, JSON.stringify(data));
	}

	destroy() {
		localStorage.removeItem(this.name);
	}
}

Adapter.STORAGE.local = LocalAdapter;
module.exports = LocalAdapter;